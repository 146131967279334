<template>
  <div class="reset-password">
    <div class="container">
      <section class="section">
        <transition name="page-fade" mode="out-in">
          <reset-password-input
            v-if="step === 0"
            class="reset-password__content"
            @submit="sendEmail"
            @input="email = $event"
            :value="email"
            type="email"
            :is-loading="isLoading"
          />

          <base-notification-box
            mode="success"
            :heading="$t('resetPassword.confirmation.heading')"
            :text="$t('resetPassword.confirmation.text')"
            withoutShadow
            v-if="step === 1"
          >
            <template name="actions">
              <base-button class="reset-password-confirmation__button" to="/">
                {{ $t("resetPassword.confirmation.button") }}
              </base-button>
            </template>
          </base-notification-box>

          <reset-password-input
            v-if="step === 2"
            class="reset-password__content"
            @submit="setNewPassword"
            @input="password = $event"
            :value="password"
            type="password"
            :is-loading="isLoading"
          />

          <base-notification-box
            mode="success"
            :heading="$t('resetPassword.passwordChanged.heading')"
            :text="$t('resetPassword.passwordChanged.text')"
            withoutShadow
            v-if="step === 3"
          >
            <template>
              <p class="reset-password__email">
                {{ email }}
              </p>
            </template>

            <template name="actions">
              <base-button
                class="reset-password-confirmation__button"
                to="/auth/sign-in"
              >
                {{ $t("nav.sign-in") }}
              </base-button>
            </template>
          </base-notification-box>
        </transition>
      </section>

      <p class="reset-password__help">
        {{ $t("resetPassword.help") }}

        <base-button type="link" to="/contact">
          {{ $t("resetPassword.contactLink") }}
        </base-button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ResetPassword',
  data: () => ({
    email: '',
    password: '',
    step: 0,
    isLoading: false,
  }),
  computed: {
    ...mapState({
      error: (state) => state.auth.error,
    }),
    errorMessage() {
      return `${this.$t('resetPassword.error.address')} ${this.email} ${this.$t(
        'resetPassword.error.text',
      )}`;
    },
    mode() {
      return this.$route.query.token && this.$route.query.id
        ? 'set_new_password'
        : 'reset_password';
    },
  },
  methods: {
    async sendEmail() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('auth/resetPassword', {
          email: this.email.toLowerCase(),
        });

        this.step = 1;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setNewPassword() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('auth/setNewUserPassword', {
          password: this.password,
          token: this.$route.query.token,
          id: this.$route.query.id,
        });

        !this.error ? this.step = 3 : false;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.mode === 'set_new_password' ? (this.step = 2) : (this.step = 0);
  },
};
</script>

<style lang="scss" scoped src="./ResetPassword.scss" />
